export default [
  { image: '1', project: 'powernapp', text: 'Google API' },
  { image: '2', project: 'traderadius', text: 'Dashboards' },
  { image: '3', project: 'negina', text: 'Courses' },
  { image: '4', project: 'linguaschools', text: 'Custom CMS' },
  { image: '5', project: 'traderadius', text: 'Dashboard' },
  { image: '6', project: 'lessonsaustralia', text: 'Search' },
  { image: '7', project: 'powernapp', text: 'Booking' },
  // { image: '8', project: 'shoehospital', text: 'ERP' },
  { image: '9', project: 'negina', text: 'eLearning' },
  { image: '10', project: 'negina', text: 'eLearning' },
  { image: '11', project: 'traderadius', text: 'Dashboards' },
  { image: '12', project: 'spp', text: 'SaaS' },
  { image: '13', project: 'linguaschools', text: 'Custom CMS' },
  { image: '14', project: 'traderadius', text: 'News' },
  { image: '15', project: 'powernapp', text: 'Find hotels' },
  { image: '16', project: 'bodyshops', text: 'ERP/CRM' },
  { image: '17', project: 'traderadius', text: 'Dashboards' },
  // { image: '18', project: 'shoehospital', text: 'ERP' },
]
