import React from 'react'

import Arrow from '../../static/icons/home.svg'
import '../styles/components/home-button.scss'

export default () => {
  const onClick = () => {
    window.fullpage_api.moveTo('home')
  }

  return (
    <button
      onClick={ onClick }
      className='home-button'
    >
      <Arrow/>
    </button>
  )
}
