import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import ReviewLinks from '../../../ReviewLinks'
import ButtonToNext from '../../../ButtonToNext'

export default () => {
  const breakpoint = useBreakpoint()

  const moveSectionDown = () => {
    fullpage_api.moveSectionDown()
  }

  return (
    <div className='home--footer'>
      <ReviewLinks noStar={ !!breakpoint.md }/>
      <ButtonToNext handleClick={ moveSectionDown }/>
    </div>
  )
}
