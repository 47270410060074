import React from 'react'

import HomeLink from '../../../HomeLink'
import Background from './_Background'
import Preview from './_Preview'
import Content from './_Content'
import Footer from './_Footer'

export default () => (
  <div className='section home'>
    <div className='home__wrapper'>
      <Background/>
      <HomeLink/>
      <Preview/>
      <Content/>
      <Footer/>
    </div>
  </div>
)
