import React, { useMemo } from 'react'
import { splitByChunks } from '../../../../helpers'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel'

import Item from './_Item'

export default ({ items }) => {
  const slides = useMemo(() => splitByChunks(items, 2), [])

  return (
    <CarouselProvider
      isIntrinsicHeight
      visibleSlides={ 1.08 }
      totalSlides={ slides.length }
    >
      <Slider>
        { slides.map((slide, index) =>
          <Slide key={ index }>
            { slide.map((item, key) =>
              <Item {...{ item, key }}/>
            ) }
          </Slide>
        ) }
      </Slider>
    </CarouselProvider>
  )
}
