import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import items from '../../../../constants/whatWeDo'

import Mobile from './_Mobile'
import Desktop from './_Desktop'

export default () => {
  const breakpoint = useBreakpoint()

  return (
    <div className='section what-we-do'>
      <h2 className="caption-primary">
        What We Do
      </h2>

      <div>
        { breakpoint.md ?
          <Mobile {...{ items }}/> :
          <Desktop {...{ items }}/>
        }
      </div>
    </div>
  )
}
