import React, { useState, useEffect } from 'react'
import { clientItems, zimalabItems } from '../../../../constants/clients'

import Map from './_Map'

// provide next group to animate
const OrderedGroupProvider = slides => {
  return new class {
    constructor() {
      // index of current slide
      this.slideIndex = 0
      // index of current group for each slide
      this.groupIndexes = slides.map(() => 0)
    }

    _incrementGroup() {
      // increment group index in slide
      this.groupIndexes[this.slideIndex] =
        (this.groupIndexes[this.slideIndex] + 1)
        % slides[this.slideIndex].length
    }
    _incrementSlide() {
      // increment slide index
      this.slideIndex =
        (this.slideIndex + 1) % slides.length
    }

    _currentSlide() {
      return slides[this.slideIndex]
    }
    _currentGroup() {
      return this._currentSlide()
        [this.groupIndexes[this.slideIndex]]
    }

    _groupIsFocused(group) {
      return !!group.mapItems
        .find(mapItem => mapItem.focused)
    }

    next() {
      let group = this._currentGroup()

      // skip focused group
      if (this._groupIsFocused(group)) {
        const slide = this._currentSlide()

        // if slide have more groups
        // than select next group in current slide
        // else select next slide

        slide.length === 1 ?
          this._incrementSlide() :
          this._incrementGroup()

        group = this._currentGroup()
      }

      this._incrementGroup()
      this._incrementSlide()

      return group
    }
  }
}
// split map items by groups and slides to animate
const createGroupsAndSlides = mapItems => {
  const groups = mapItems
    // split mapItems by group value
    .reduce((groups, mapItem) => {
      // skip not grouped items
      if (mapItem.group === undefined) return groups
      if (!groups[mapItem.group]) groups[mapItem.group] = []
      return groups[mapItem.group].push(mapItem) && groups
    }, [])
    // add properties to group
    .map((group) => ({
      mapItems: group,
      slide: group[0].slide,
      number: group[0].group,
    }))

  const slides = groups
    // split groups by slides
    .reduce((slides, group) => {
      if (!slides[group.slide]) slides[group.slide] = []
      return slides[group.slide].push(group) && slides
    }, [])

  return [groups, slides]
}
// helper for work with map items and dom elements
const MapElementsController = (groups, mapItems) => {
  return new class {
    find(mapItem) {
      return mapItem.element = mapItem.element ||
        (() => {
          const index = mapItems.indexOf(mapItem) + 1
          const selector = `.our-clients__item:nth-child(${ index })`
          return document.querySelector(selector)
        })()
    }
    findClick(mapItem) {
      return mapItem.click = mapItem.click ||
        (() => {
          const index = mapItems.indexOf(mapItem) + 1
          const selector = `.our-clients__click:nth-child(${ index })`
          return document.querySelector(selector)
        })()
    }

    focus(mapItem) {
      const showFocused = mapItem => {
        if (mapItem.group === undefined) return

        const group = groups.find(group =>
          group && group.number === mapItem.group)

        const lastItem = group.mapItems
          .find(mapItem => mapItem.shown)
        lastItem && this.hide(lastItem)

        this.show(mapItem)
      }

      const setFocus = (mapItem, value) => {
        if ((mapItem.focused = value)) {
          showFocused(mapItem)
          this.findClick(mapItem).classList.add('focused')
        } else {
          this.findClick(mapItem).classList.remove('focused')
        }
      }

      const focused = mapItems
        .find(mapItem => mapItem.focused)

      if (focused && focused === mapItem)
        return setFocus(mapItem, undefined)

      focused && setFocus(focused, undefined)
      setFocus(mapItem, true)
    }

    show(mapItem) {
      if (mapItem.shown) return
      mapItem.shown = true
      this.find(mapItem).classList.add('shown')
      this.find(mapItem).classList.remove('hidden')
    }
    hide(mapItem) {
      if (!mapItem.shown) return
      mapItem.shown = false
      this.find(mapItem).classList.add('hidden')
      this.find(mapItem).classList.remove('shown')
    }
    toggle(mapItem) {
      mapItem.shown ?
        this.hide(mapItem) :
        this.show(mapItem)
    }
  }
}

export default () => {
  const [mapItems, setMapItems] = useState([])
  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    const mapItems = [...clientItems, ...zimalabItems]

    const setupPositionsAsync = async () => {
      const getMapRectAsync = async map => {
        while (true) {
          const mapRect = map.getBoundingClientRect()
          if (mapRect.width !== 0) return mapRect
          await new Promise(r => setTimeout(r, 10))
        }
      }

      const map = document.querySelector('.our-clients__map')
      const points = map.querySelectorAll('path')
      const mapRect = await getMapRectAsync(map)

      mapItems.forEach(mapItem => {
        const point = points[mapItem.pointIndex]
        const pointRect = point.getBoundingClientRect()

        const position = {
          y: pointRect.top - mapRect.top + pointRect.height / 2,
          x: pointRect.left - mapRect.left + pointRect.width / 2,
        }

        position.x = position.x * 100 / mapRect.width
        position.y = position.y * 100 / mapRect.height

        mapItem.position = position
      })
    }
    const setupGroups = () => {
      let groupCounter = 0
      const groupRadius = 7

      // add groups to mapItems
      mapItems.forEach(mapItem => {
        if (mapItem.group === undefined)
          mapItem.group = groupCounter++

        mapItems.forEach(mapItemAround => {
          if (mapItemAround.group !== undefined) return

          const isInGroup =
            mapItemAround.position.x > mapItem.position.x - groupRadius &&
            mapItemAround.position.x < mapItem.position.x + groupRadius
          if (isInGroup) mapItemAround.group = mapItem.group
        })
      })

      // remove group in zimalab items
      mapItems.forEach(mapItem => {
        const isZimalab =
          zimalabItems.find(zimalabItem =>
            zimalabItem.title === mapItem.title &&
            zimalabItem.country === mapItem.country)
        if (isZimalab) mapItem.group = undefined
      })

      // remove group in alone items
      mapItems.forEach(mapItem => {
        const groupItems = mapItems.filter(
          item => item.group === mapItem.group)
        const isAlone = groupItems.length === 1
        if (isAlone) mapItem.group = undefined
      })
    }
    const setupSlides = () => {
      mapItems.forEach(mapItem => mapItem.slide =
        Math.floor(3 * mapItem.position.x / 100)
      )
    }

    setupPositionsAsync().then(() => {
      setupGroups()
      setupSlides()
      setMapItems(mapItems)
    })
  }, [])
  useEffect(() => {
    if (!mapItems.length) return

    const [groups, slides] = createGroupsAndSlides(mapItems)
    const controller = MapElementsController(groups, mapItems)
    const provider = OrderedGroupProvider(slides)

    const animation = new class {
      constructor() {
        this.section = document.querySelector('.our-clients')
      }

      // first animation
      animateStart() {
        this.startPlayed = true

        // show first item in each group
        groups.forEach(group => controller.show(group.mapItems[0]))

        // show single items
        const singleFilter = mapItem => mapItem.group === undefined
        const singleMapItems = mapItems.filter(singleFilter)
        singleMapItems.forEach(mapItem => controller.show(mapItem))
      }
      // interval animation
      animateStep() {
        // hide last shown map item and show next
        const mapItems = provider.next().mapItems

        const lastItem = mapItems.find(mapItem => mapItem.shown)
        const nextIndex = mapItems.indexOf(lastItem) + 1
        const nextItem = mapItems[nextIndex % mapItems.length]

        controller.toggle(lastItem)
        controller.toggle(nextItem)
        setCurrentSlide(nextItem.slide)
      }

      _getSectionActive() {
        return this.section.classList.contains('active')
      }

      startAnimation() {
        if (this.intervalId) return
        this.intervalId = setInterval(() => {
          if (this._getSectionActive()) {
            this.startPlayed ?
              this.animateStep() :
              this.animateStart()
          }
        }, 3000)
      }
      stopAnimation() {
        if (!this.intervalId) return
        clearInterval(this.intervalId)
        this.intervalId = undefined
      }
    }
    const clickEvents = new class {
      constructor() {
        this.map = document.querySelector('.our-clients__map')
        const clicks = document.querySelector('.our-clients__map__clicks')
        this.clicks = [...clicks.children]
      }
      _focusMapItem(target) {
        if (!animation.startPlayed) {
          animation.animateStart()
          animation.stopAnimation()
          animation.startAnimation()
        }

        const index = this.clicks.indexOf(target)
        controller.focus(mapItems[index])

        if (innerWidth <= 991) {
          if (mapItems[index].focused) {
            animation.stopAnimation()
          } else {
            animation.animateStep()
            animation.startAnimation()
          }
        }
      }
      _skipAnimation() {
        const focused = mapItems.find(
          mapItem => mapItem.focused)
        focused && controller.focus(focused)

        animation.startPlayed ?
          animation.animateStep() :
          animation.animateStart()

        animation.stopAnimation()
        animation.startAnimation()
      }

      _handler(event) {
        if (this._isDisabled()) return

        const contains = className => event
          .target.classList.contains(className)

        if (contains('our-clients__click'))
          this._focusMapItem(event.target)

        if (contains('our-clients__map'))
          this._skipAnimation()
      }
      _isDisabled() {
        if (this.disabled) return true
        this.disabledTimer = setTimeout(
          () => this.disabled = false, 750)
        return !(this.disabled = true)
      }

      bind() {
        this.map.addEventListener('click', this._handler.bind(this))
      }
      unbind() {
        this.map.removeEventListener('click', this._handler.bind(this))
      }

      clear() {
        this.unbind()
        this.disabledTimer &&
          clearTimeout(this.disabledTimer)
      }
    }

    clickEvents.bind()
    animation.startAnimation()

    return () => {
      clickEvents.clear()
      animation.stopAnimation()
    }
  }, [mapItems])

  return <Map {...{ mapItems, currentSlide }}/>
}
