import React from 'react'

import '../styles/components/slide-buttons.scss'

import ArrowBack from '../../static/icons/angle-arrow-left.svg'
import ArrowNext from '../../static/icons/angle-arrow-right.svg'

export default () => {
  const moveSlide = (button, direction) => {
    const section = button.closest('.section')
    const selector = `.carousel__${ direction }-button`
    section.querySelector(selector).click()
  }

  const onBackClick = event => moveSlide(event.currentTarget, 'back')
  const onNextClick = event => moveSlide(event.currentTarget, 'next')

  return (
    <div className='slide-buttons'>
      <button className='slide-buttons__button'
              onClick={ onBackClick }>
        <ArrowBack/>
      </button>

      <button className='slide-buttons__button'
              onClick={ onNextClick }>
        <ArrowNext/>
      </button>
    </div>
  )
}
