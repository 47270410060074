import LoadImage from '../../../LoadImage'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import React, { useState, useEffect, useMemo } from 'react'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel'

import MapSvg from '../../../../../static/images/landing/map.svg'
import { zimalabItems, mapImageSize } from '../../../../constants/clients'

const MapItem = ({ mapItem }) => {
  const breakpoint = useBreakpoint()

  const getIsZimalab = () => {
    return !!zimalabItems.find(zimalabItem =>
      zimalabItem.title === mapItem.title
    )
  }
  const getIsTopdown = () => (
    zimalabItems[1].title === mapItem.title &&
    zimalabItems[1].country === mapItem.country
  )
  const getIsLeft = () => {
    return !breakpoint.md ? mapItem.position.x > 80 :
      mapItem.position.x - (33 * mapItem.slide) > 20
  }

  const style = {
    top: mapItem.position.y + '%',
    left: mapItem.position.x + '%'
  }
  const className = 'our-clients__item'
    + (getIsZimalab() ? ' zimalab' : '')
    + (getIsTopdown() ? ' topdown' : '')
    + (getIsLeft() ? ' left' : '')

  const requireFlag = () => require(
    `../../../../../static/icons/countries/${ mapItem.country }.webp`
  )

  return (
    <div {...{ className, style }}>
      <div className='our-clients__item__col'>
        <div className='our-clients__item__flag'>
          <LoadImage lazyLoad
            primaryLg={ requireFlag() }
            alt={ 'Flag ' + mapItem.country }
          />
        </div>
        <div className='our-clients__item__line'/>
      </div>
      <div className='our-clients__item__col'>
        <h3 className='our-clients__item__title'>{ mapItem.title }</h3>
        <p className='our-clients__item__text'>{ mapItem.text }</p>
      </div>
    </div>
  )
}

export default ({ mapItems, currentSlide }) => {
  const [carouselProps, setCarouselProps] = useState({})
  const breakpoint = useBreakpoint()

  useEffect(() => {
    if (breakpoint.md) {
      carouselProps.totalSlides = 3;
      carouselProps.naturalSlideWidth = mapImageSize.width / 3;
    } else {
      carouselProps.totalSlides = 1;
      carouselProps.naturalSlideWidth = mapImageSize.width;
    }

    setCarouselProps({ ...carouselProps })
  }, [breakpoint.md])
  useEffect(() => {
    const clear = points => {
      points.forEach(point =>
        point.classList.remove('active')
      )
    }
    const active = (points, mapItems) => {
      mapItems.map(dataItem => {
        const point = points[dataItem.pointIndex]
        point.classList.add('active')
      })
    }

    const selector = '.our-clients__map path'
    const points = document.querySelectorAll(selector)
    clear(points); active(points, mapItems)
  }, [mapItems])

  const slide = useMemo(() =>
    breakpoint.md ? currentSlide : 1
  , [currentSlide, breakpoint.md])

  return (
    <CarouselProvider
      naturalSlideHeight={ mapImageSize.height }
      { ...carouselProps } currentSlide={ slide }
      dragEnabled={ false } touchEnabled={ false }
    >
      <Slider><Slide>
        <div className='our-clients__map'>
          <div className='our-clients__map__image'>
            <MapSvg/>
          </div>
          <div className='our-clients__map__items'>
            { mapItems.map((mapItem, key) =>
              <MapItem {...{ mapItem, key }}/>
            ) }
          </div>
          <div className='our-clients__map__clicks'>
            { mapItems.map((mapItem, key) =>
              <div
                className='our-clients__click'
                {...{ key }} style={{
                  top: mapItem.position.y + '%',
                  left: mapItem.position.x + '%'
                }}
              />
            ) }
          </div>
        </div>
      </Slide></Slider>
    </CarouselProvider>
  )
}
