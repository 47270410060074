export const clientItems = [
  {
    pointIndex: 2253,
    country: 'united-states',
    title: 'Adsblocking SaaS',
    text: 'Security, IL, Chicago'
  },
  {
    pointIndex: 1900,
    country: 'united-states',
    title: 'ERP Product API',
    text: 'Retail, MA, Lewiston'
  },
  {
    pointIndex: 1727,
    country: 'united-states',
    title: 'Data Management',
    text: 'Legal, Rockville, Maryland'
  },
  {
    pointIndex: 1235,
    country: 'united-states',
    title: 'Freight Trucks ERP',
    text: 'Transportation, CA, San Diego'
  },
  {
    pointIndex: 1325,
    country: 'united-states',
    title: 'Payment Solution',
    text: 'Finance, CA, Redwood City'
  },
  {
    pointIndex: 2349,
    country: 'united-kingdom',
    title: 'Gift Shop Directory',
    text: 'Lifestyle, London'
  },
  {
    pointIndex: 1805,
    country: 'spain',
    title: 'Language Schools Website',
    text: 'Education, Barcelona'
  },
  {
    pointIndex: 1999,
    country: 'france',
    title: 'Construction Membership',
    text: 'Construction, Carpentras'
  },
  {
    pointIndex: 2112,
    country: 'switzerland',
    title: 'Quotes Directory',
    text: 'Education, Lausanne'
  },
  {
    pointIndex: 2461,
    country: 'netherlands',
    title: 'Hotel Booking Platform',
    text: 'Travel, Breda'
  },
  {
    pointIndex: 1555,
    country: 'israel',
    title: 'Music Lessons',
    text: 'Education, Rehovot'
  },
  {
    pointIndex: 28,
    country: 'australia',
    title: 'Vocalists Social Network',
    text: 'Music, Melbourne'
  },
  {
    pointIndex: 37,
    country: 'australia',
    title: 'Novel Planner',
    text: 'Lifestyle, Newcastle'
  },
  {
    pointIndex: 113,
    country: 'australia',
    title: 'Music Teachers',
    text: 'Education, Brisbane'
  },
  {
    pointIndex: 28,
    country: 'australia',
    title: 'Cryptocurrency Exchange',
    text: 'Finance, Melbourne'
  },
  {
    pointIndex: 66,
    country: 'australia',
    title: 'Monitoring Dashboard',
    text: 'Engineering, Wollongong'
  },
  {
    pointIndex: 113,
    country: 'australia',
    title: 'Home Appliance eCommerce',
    text: 'Retail, Brisbane'
  },
]

export const zimalabItems = [
  {
    pointIndex: 3439,
    country: 'kazakhstan',
    title: 'Zimalab office',
    text: 'Astana, Kazakhstan'
  },
  {
    pointIndex: 1715,
    country: 'turkey',
    title: 'Zimalab office',
    text: 'Alanya, Turkey'
  },
]

export const mapImageSize = {
  width: 1180, height: 469
}
