import React from 'react'
import MapProvider from './_MapProvider'

const InfoNumber = ({ value }) => (
  <span className="our-clients__info__number">{ value }</span>
)

export default () => (
  <div className="section our-clients lazyload">
    <h2 className="caption-primary">Our Clients</h2>
    <MapProvider/>
    <div className="our-clients__info">
      <InfoNumber value='210+'/> projects
      in <InfoNumber value='15'/> countries
    </div>
  </div>
)
