import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { welcomePopupAction } from '../../../../redux/actions/welcomePopupAction'

export default () => {
  const welcomePopupIsOpen = useSelector(
    state => state.welcomePopupIsOpen)
  const dispatch = useDispatch()

  return (
    <div className='home--content'>
      <h1 className='home--content__title'>
        Web Apps & Automations
      </h1>

      <p className='home--content__text'>
        We develop high quality custom web apps,
        eCommerce, MVP, SaaS, ERP, CRM solutions,
        API integrations and business automations.
      </p>

      <button className='home--content__button'
        onClick={ () => dispatch(welcomePopupAction(true)) }>
        Get a free consultation
      </button>
    </div>
  )
}
