import { useSelector } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

export default ({ projectText }) => {
  const breakpoint = useBreakpoint()
  const [active, setActive] = useState(false)
  const currentSection = useSelector(state => state.currentSectionIndex)

  useEffect(() => {
    if (breakpoint.md) return

    const project = document.querySelector('.home--preview__project')
    const preview = document.querySelector('.home--preview')

    const onMouseMove = ({ clientX, clientY }) => {
      const previewRect = preview.getBoundingClientRect()

      project.style =
        'top: ' + parseInt(clientY - previewRect.y) + 'px;' +
        'left: ' + parseInt(clientX - previewRect.x) + 'px;'
    }

    preview.addEventListener('mousemove', onMouseMove)
    return () => preview.removeEventListener('mousemove', onMouseMove)
  }, [breakpoint.md])
  useEffect(() => {
    if (breakpoint.md) return
    if (currentSection !== 0) {
      setActive(false)
      return
    }

    const preview = document.querySelector('.home--preview')

    const enableActive = () => setActive(true)
    const disableActive = () => setActive(false)

    preview.addEventListener('mouseenter', enableActive)
    preview.addEventListener('mouseleave', disableActive)

    return () => {
      preview.removeEventListener('mouseenter', enableActive)
      preview.removeEventListener('mouseleave', disableActive)
    }
  }, [breakpoint.md, currentSection === 0, active])

  return breakpoint.md || (
    <div
      className={
        'home--preview__project' +
        (active ? ' active' : '')
      }
      data-text={ projectText }
    ></div>
  )
}
