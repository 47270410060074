import React, { useMemo, useState } from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import ProjectCursor from './_ProjectCursor'
import previews from '../../../../constants/previews'

export default () => {
  const breakpoint = useBreakpoint()
  const lines = useMemo(() => {
    const linesMobile = () => {
      const lineLength = Math.floor(previews.length / 3)

      return [
        previews.slice(0, lineLength),
        previews.slice(lineLength, previews.length - lineLength),
        previews.slice(previews.length - lineLength, previews.length),
      ]
    }
    const linesDesktop = () => {
      const lineLength = Math.floor(previews.length / 2)

      return [
        previews.slice(0, lineLength),
        previews.slice(lineLength, previews.length),
      ]
    }

    return breakpoint.md ?
      linesMobile() : linesDesktop()
  }, [breakpoint.md])
  const [projectText, setProjectText] = useState(null)

  const requirePreview = name => {
    return require(
      `../../../../../static/images/landing/previews/${ name }.webp`
    )
  }

  return (
    <div className='home--preview'>
      <div className='home--preview__container'>
        <div className='home--preview__track'>
          { lines.map((line, index) =>
            <div className='home--preview__line' key={ index }>
              { [...Array(2)].map(() =>
                // repeat all images in each line twice
                // is using for infinite scrolling animation
                line.map((preview, index) =>
                  <a
                    target='_blank'
                    className='home--preview__image'
                    href={ '/portfolio/' + preview.project }
                    key={ index } data-text={ preview.project }
                    onMouseLeave={ () => setProjectText(null) }
                    onMouseEnter={ () => setProjectText(preview.text) }
                  >
                    <img src={ requirePreview(preview.image) } alt=''/>
                  </a>
                )
              ) }
            </div>
          ) }
        </div>
      </div>

      { breakpoint.md ?
        <div className='home--preview__over'></div> :
        <ProjectCursor projectText={ projectText }/>
      }
    </div>
  )
}
