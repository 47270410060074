import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import ReactFullpage from '@fullpage/react-fullpage'
import { useDispatch } from 'react-redux'
import { useLocation } from "@reach/router"
import { currentSectionIndex } from '../redux/actions/fullpage'
import loadable from '@loadable/component'

import Layout from '../components/Layout'
import Home from '../components/pages/landing-page/Home/Section'
import WhatWeDo from '../components/pages/landing-page/WhatWeDo'
import OurWork from '../components/pages/landing-page/OurWork'
import OurServices from '../components/pages/landing-page/OurServices'
import OurTeam from '../components/pages/landing-page/OurTeam'
import OurClients from '../components/pages/landing-page/OurClients/Section'
import ContactUs from '../components/contact-us'
import Reviews from '../components/pages/landing-page/Reviews'
import HomeButton from '../components/HomeButton'

import { setVhUnit } from '../helpers'

import '../styles/pages/landing-page/index.scss'

const Menu = loadable(() => import('../components/menu'))
const Overlay = loadable(() => import('../components/overlay/Overlay'))

const anchors = [
  'home',
  'what-we-do',
  'our-work',
  'our-team',
  'our-services',
  'our-clients',
  'reviews',
  'contact-us',
]

export default () => {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    setVhUnit()
    window.addEventListener('resize', setVhUnit)

    return () => window.removeEventListener('resize', setVhUnit)
  }, [])

  // Jump to anchor if it exists
  useEffect(() => {
    if (window && location.state && location.state.anchor) {
      setTimeout(() => window.fullpage_api.moveTo(location.state.anchor), 100)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('lazybeforeunveil', function (e) {
      var bg = e.target.getAttribute('data-bg');
      if (bg) {
        e.target.style.backgroundImage = 'url(' + bg + ')';
      }
    });
  }, [])

  const handleLeave = (_, destination) => {
    dispatch(currentSectionIndex(destination.index))
  }

  return (
    <Layout>
      <Helmet>
        <title>Zimalab | Fullstack Web Development Team</title>
        <meta name="description" content="Zimalab provides full-stack web development services. We create custom web apps, eCommerce, SaaS, ERP/CRM solutions, API integrations, and business automations"/>
        <meta name="Keywords" content="Symfony developer, Symfony development team, Hire symfony developer, Hire PHP developer, Hire laravel developer, Hire php agency, Hire web agency, Hire website developer, Hire API developer, Custom website developer, Hire php freelancer, ERP developer, CRM developer, Saas developer, MVP developer, IT software developer, ecommerce developer, Fullstack developer, backend developer, fintech developer"/>
        <link rel="preload" href="https://unpkg.com/fullpage.js@3.0.1/dist/fullpage.min.css" as="style" onload="this.onload=null;this.rel='stylesheet'" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Helmet>
      <main className="home-page">
        <ReactFullpage
          licenseKey='7C2262BC-E6044700-BA605146-B340179C'
          anchors={[...anchors]}
          lockAnchors={true}
          onLeave={handleLeave}
          lazyLoading={true}
          normalScrollElements='.menu.menu--opened, .contact-form__textarea'
          render={({ state, fullpageApi }) => (
            <ReactFullpage.Wrapper>
              <Home />
              <WhatWeDo />
              <OurWork />
              <OurTeam />
              <OurServices />
              <OurClients />
              <Reviews />
              <ContactUs hasCopyright={true} />
            </ReactFullpage.Wrapper>
          )}
        />
        <Overlay/>
        <Menu/>
        <HomeButton/>
        {/*<WelcomePopup/>*/}
      </main>
    </Layout>
  )
}
