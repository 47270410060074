import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import loadable from '@loadable/component'

import SlideButtons from '../../SlideButtons'

const OurServicesMobile = loadable(() => import('./OurServicesMobile'))
const OurServicesDesktop = loadable(() => import('./OurServicesDesktop'))

export default () => {
  const breakpoints = useBreakpoint()

  return (
    <div className="section our-services lazyload">
      <h2 className="caption-primary">
        Our Services { breakpoints.md || <SlideButtons/> }
      </h2>

      {/* Condition is wrapped in <div> otherwise an error occurs when condition changes */}
      <div>
      {
        breakpoints.md ?
          <OurServicesMobile /> :
          <OurServicesDesktop />
      }
      </div>
    </div>
  )
}
