export default [
  {
    title: 'Web Apps',
    icon: 'apps',
    list: [
      'From scratch',
      'MVP creation and launch',
      'UI/UX prototyping',
      'Ongoing support ',
    ],
    text: 'Custom web application and websites from scratch. MVP creation and launch, ongoing support and maintenance upon release'
  },
  {
    title: 'ERP/CRM',
    icon: 'crm',
    list: [
      'Dashboards',
      'Orders & Client management',
      'Statistics & Reporting',
      'API integrations',
    ],
    text: 'Custom ERP/CRM solutions for business, dashboards for client and orders management, reports, analytics and API integrations'
  },
  {
    title: 'SAAS',
    icon: 'saas',
    list: [
      'Cloud based web apps',
      'Multi tenancy',
      'Subscriptions',
      'Custom billing',
    ],
    text: 'SaaS web applications with advanced functionalities, multi-tenancy, custom billing, reporting and subscription based access'
  },
  {
    title: 'Ecommerce',
    icon: 'shop',
    list: [
      'Online Stores',
      'Marketplaces',
      'Speed & SEO optimisations',
      'Sylius based development',
    ],
    text: 'Custom eCommerce solution based on Sylius framework. High speed and SEO optimization, admin panel for store and orders management'
  },
  {
    title: 'API',
    icon: 'api',
    list: [
      'API integrations',
      'API to API connection',
      'REST API development',
      'Stripe, Paypal, Shopify APIs',
    ],
    text: 'Integration of Stripe, Paypal, Twilio, Shopify, Saleforce, QuickBooks, Google, Facebook, Klaviyo or any other API into your software'
  },
  {
    title: 'Scripts',
    icon: 'scripts',
    list: [
      'Parsing data',
      'Image processing',
      'PDF generation',
      'Data visualisation',
    ],
    text: 'Parsing data from various sources, pdf generation, image processing, ffmpeg, data import & export, data visualisation'
  },
]
