import React, { useMemo } from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

const Icon = ({ iconName, isDesktop }) => {
  const icon = useMemo(() => {
    const name = iconName + (isDesktop ? '-desktop' : '')
    return require(`../../../../../static/icons/what-we-do/${ name }.svg`)
  }, [iconName, isDesktop])

  return React.createElement(icon)
}

export default ({ item }) => {
  const breakpoint = useBreakpoint()

  return (
    <div className='what-we-do-item'>
      <div className='what-we-do-item__header'>
        <h2 className='what-we-do-item__title'>
          { item.title }
        </h2>
        <Icon
          iconName={ item.icon }
          isDesktop={ !breakpoint.md }
        />
      </div>
      <div className='what-we-do-item__content'>
        { breakpoint.md ?
          <ul className='what-we-do-item__list'>
            { item.list.map((point, index) =>
              <li className='what-we-do-item__list-item' key={ index }>
                { point }
              </li>
            ) }
          </ul> :
          <p className='what-we-do-item__text'>
            { item.text }
          </p>
        }
      </div>
    </div>
  )
}
