import React, { useMemo } from 'react'

import { splitByChunks } from '../../../../helpers'

import Item from './_Item'

export default ({ items }) => (
  <div className='what-we-do__container'>
    { items.map((item, key) =>
      <Item {...{ item, key }}/>
    ) }
  </div>
)
