import React from 'react'

export default () => (
  <div className='home--background'>
    {/* 18 is circles count to fill all screen */}
    {/* it is worked when circles width is multiple of 8vmax */}
    { [...Array(18).keys()].map(key => {
      const className = 'home--background__circle'
      return <div {...{ className, key }}></div>
    }) }
  </div>
)
