import LoadImage from '../../LoadImage'
import React, { useEffect } from 'react'
import * as Carousel from 'pure-react-carousel'
import { scrollToContactUs } from '../../../helpers'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import { teamItems } from '../../../constants'

const Ending = () => {
  let mouseMoved = false

  const onMouseMove = () => mouseMoved = true
  const onMouseDown = () => mouseMoved = false
  const onClick = () => mouseMoved || scrollToContactUs()

  return (
    <div className='our-team__ending'
      {...{ onMouseMove, onMouseDown, onClick }}>
      <p className='our-team__ending__text'>
        Work with us and get to know more professionals
      </p>
    </div>
  )
}

export default () => {
  const breakpoint = useBreakpoint()

  const requireAvatar = (teamItem, type) => require(
    `../../../../static/images/landing/avatars/${ teamItem.avatar }.${ type }`
  )

  useEffect(() => {
    const ending = document.querySelector('.our-team__ending')
    const avatar = document.querySelector('.team-item__avatar')

    if (breakpoint.md)
      return ending.removeAttribute('style')

    const onTick = () => {
      ending.style.height = avatar.height + 'px'
      ending.style.width = avatar.width * 2 + 20 + 'px'
    }

    const intervalId = setInterval(onTick, 200)
    return () => clearInterval(intervalId)
  }, [breakpoint.md])

  const carouselProps = {
    visibleSlides: (() => {
      if (!breakpoint.xl) return 5
      if (!breakpoint.md) return 4
      return 1.15
    })(),
    totalSlides: (() => (
      teamItems.length +
      (breakpoint.md ? 1 : 2)
    ))()
  }

  return (
    <div className='section our-team lazyload'>
      <h2 className='caption-primary'>
        Our Team
      </h2>

      <div>{ breakpoint.md ||
        <h3 className='our-team__title'>
          <span className='our-team__title__number'>35+</span>
          <span className='our-team__title__first'>professionals</span>
          <span className='our-team__title__second'>10+ years expertise</span>
        </h3>
      }</div>

      <Carousel.CarouselProvider
        isIntrinsicHeight { ...carouselProps }
      >
        <Carousel.Slider>

          { teamItems.map((teamItem, key) =>
            <Carousel.Slide {...{ key }}>
              <div className='team-item'>
                <LoadImage
                  alt='avatar' lazyLoad
                  className='team-item__avatar'
                  primaryLg={ requireAvatar(teamItem, 'webp') }
                  secondaryLg={ requireAvatar(teamItem, 'png') }
                />
                <h4 className='team-item__name'>{ teamItem.name }</h4>
                <h5 className='team-item__position'>{ teamItem.position }</h5>
                <p className='team-item__about'>{ teamItem.about }</p>
              </div>
            </Carousel.Slide>
          ) }

          { breakpoint.md ? <>
            <Carousel.Slide>
              <Ending/>
            </Carousel.Slide>
          </> : <>
            <Carousel.Slide></Carousel.Slide>
            <Carousel.Slide></Carousel.Slide>
            <Ending/>
          </> }

        </Carousel.Slider>
      </Carousel.CarouselProvider>
    </div>
  )
}
